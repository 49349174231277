import axios from 'axios'
import {removeLocalStorage, setLocalStorage, setToken} from "@/utils/storage";
import router from "@/router";
import {everyPropKey, snakeToCamelCase} from "@/utils/dataTransformation";
import store from '@/store/index'

export const login = async (login, password) => {
  const {data} = await axios.post('auth/login', {
    username: login,
    password,
    grant_type: 'password',
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    scope: ''
  })
  setToken(`${data.token_type} ${data.access_token}`)
  setLocalStorage('refresh_token', `${data.token_type} ${data.refresh_token}`)
  
  const now = new Date()
  const secondsSinceEpoch = Math.round(now.getTime() / 1000)
  setLocalStorage('expires_at', secondsSinceEpoch + data.expires_in - 60)
  axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
  await router.push('/')
}

export const fetchUserDetails = async () => {
  const {data} = await axios.get('user/details')
  return everyPropKey(data, snakeToCamelCase)
}

export const remindPassword = (email) => {
  return axios.post('auth/send-reset-password', {email})
}

export const resetPassword = async (password, token) => {
  return axios.post(`auth/reset-password/${token}`, {password})
}

export const activateAccount = (token) => {
  return axios.post('activate-account', {token})
}

export const logout = async () => {
  try {
    await axios.delete('auth/logout')
    store.commit('auth/SET_INIT_DATA')
  } catch (e) {
    console.log('doesn`t requested for logout')
  } finally {
    removeLocalStorage('token')
    await router.push('/login')
  }
}
