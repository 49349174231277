<template>
  <v-row
    class="align-center"
    justify="center"
  >
    <v-col
      class="pa-4"
      cols="12"
      lg="4"
      md="6"
      sm="8"
      xs="6"
    >
      <div v-if="loading">
        <h2>Weryfiacja...</h2>
      </div>
      <div v-if="!loading && success">
        <h2>Konto jest aktywne</h2>
        <p class="mb-10">Zostaniesz przekierowany na stronę logowania za {{ redirectionTime }}...</p>
      </div>
      <div v-if="!loading && !success">
        <h2>Błąd aktywacji konta</h2>
        <p class="mb-10">Błąd aktywacji konta. Twoje konto było aktywowane wcześniej albo link aktywacyjny jest przeterminowany lub uszkodzony. Skontaktuj się z <a
            href="#"
            target="_blank"
          >Somewhere</a> jeżeli nie uda się aktywować konto.</p>
      </div>
      <v-row
        class="align-center"
        justify="center"
      >
        <v-col cols="6"></v-col>
        <v-col
          class="text-right"
          cols="6"
        >
          <v-btn
            :to="'/login'"
            link
            small
            text
          >
            Wróć do logowania
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {activateAccount} from "@/services/authService";
import {notifyStandardError} from "@/services/notificationService";

export default {
  name: 'ActivateAccount',
  data: () => ({
    loading: true,
    success: false,
    redirectionTime: 5
  }),
  mounted() {
    this.activateAccount();
  },
  methods: {
    async activateAccount() {
      try {
        await activateAccount(this.$route?.params?.token)
        this.success = true
        this.delayRedirectToLogin()
      } catch (e) {
        notifyStandardError()
      } finally {
        this.loading = false
      }
    },
    delayRedirectToLogin() {
      const redirectAfter = this.redirectionTime
      for (let i = 0; i <= redirectAfter; i++) {
        setTimeout(() => {
          this.redirectionTime--;
        }, 1000);
      }
      this.$router.push({name: 'Login'});
    }
  }
}
</script>

<style scoped>

</style>
